import type { SessionData } from '#auth';
import type { CheckfloProfile } from '../common/dtos';
import { AccountAppType } from '../common/enums/account-app-type';
import { AccountStatus } from '../common/enums/account-status';
import { DwollaCustomerStatus } from '../common/enums/dwolla-customer-status';
import { DwollaCustomerType } from '../common/enums/dwolla-customer-type';
import { StripeVerificationStatus } from '../common/enums/stripe-verification-status';

export const isVerificationInProgress = (
  auth: SessionData,
  checkfloProfile: CheckfloProfile,
  bankForPaymentsId: string,
) => {
  const isDwollaVerificationPending: boolean =
    auth.verifiedWithDwolla === DwollaCustomerStatus.Pending ||
    auth.controllerVerified === DwollaCustomerStatus.Pending;
  const isStipeVerificationPending: boolean =
    auth.accountAppType === AccountAppType.AgentSnap &&
    auth.type !== DwollaCustomerType.ReceiveOnly &&
    (auth.stripeVerificationStatus?.id === StripeVerificationStatus.Pending || auth.isStripePersonVerificationPending);
  const isCheckfloProfileExists = Boolean(
    checkfloProfile?.bankStatus && Object.keys(checkfloProfile.bankStatus).length,
  );
  const isCheckfloProfileActive: boolean = Boolean(
    checkfloProfile.bankStatus?.[bankForPaymentsId] === AccountStatus.Activated,
  );
  const isCheckfloPending: boolean = isCheckfloProfileExists && isCheckfloProfileActive;

  return isDwollaVerificationPending || isStipeVerificationPending || isCheckfloPending;
};
