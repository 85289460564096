export enum StripeVerificationStatus {
  Unverified = 1,
  Pending = 2,
  Verified = 3,
  Restricted = 4,
  Deleted = 5,
}

export const stripeVerificationStatusMap: Record<number, string> = {
  [StripeVerificationStatus.Pending]:
    'You have completed the onboarding process. To confirm your identity the process will take 1-3 business days',
  [StripeVerificationStatus.Restricted]: 'Continue verification',
  [StripeVerificationStatus.Deleted]: 'Blocked',
};
